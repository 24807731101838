import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

function swiperTestimonials() {
  new Swiper(".testimonials .swiper", {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".testimonials .next",
      prevEl: ".testimonials .prev"
    }
  });
}

function swiperSocial() {
  new Swiper(".social .swiper", {
    modules: [Navigation],
    // loop: true,
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 2
      }
    },
    navigation: {
      nextEl: ".social .next",
      prevEl: ".social .prev"
    }
  });
}

function swiperTeam() {
  new Swiper(".team .swiper", {
    // loop: true,
    slidesPerView: 1
  });
}

function swiperBrands() {
  new Swiper(".brands .swiper", {
    // loop: true,
    slidesPerView: "auto"
  });
}

function swiperBrandsHome() {
  new Swiper(".brands .swiper", {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 32,
    speed: 5000,
    autoplay: {
      delay: 50,
      disableOnInteraction: false
    },
    breakpoints: {
      768: {
        spaceBetween: 88
      }
    }
  });
}

function swiperSlides() {
  new Swiper(".slides .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: ".slides .next",
      prevEl: ".slides .prev"
    }
  });
}

function swiperNews() {
  new Swiper(".news .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: ".news .next",
      prevEl: ".news .prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      }
    }
  });
}

function swiperTiles() {
  new Swiper(".module--tile-carousel .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    autoHeight: false,
    navigation: {
      nextEl: ".module--tile-carousel .next",
      prevEl: ".module--tile-carousel .prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      }
    }
  });
}

function swiperUsage() {
  new Swiper(".usage .swiper", {
    modules: [Navigation],
    navigation: {
      nextEl: ".usage .next",
      prevEl: ".usage .prev"
    }
  });
}

function swiperBenefits() {
  new Swiper(".benefits .swiper", {
    modules: [Pagination, Navigation],
    spaceBetween: 56,
    pagination: {
      el: ".benefits .numbers",
      type: "fraction"
    },
    navigation: {
      nextEl: ".benefits .next",
      prevEl: ".benefits .prev"
    }
  });
}

function swiperMediaCarousel() {
  new Swiper(".module--text-content-media-carousel .swiper", {
    modules: [Pagination, Navigation],
    spaceBetween: 56,
    pagination: {
      el: ".module--text-content-media-carousel .numbers",
      type: "fraction"
    },
    navigation: {
      nextEl: ".module--text-content-media-carousel .next",
      prevEl: ".module--text-content-media-carousel .prev"
    }
  });
}

function swiperBlog() {
  new Swiper("header .swiper", {
    modules: [Navigation],
    spaceBetween: 56,
    navigation: {
      nextEl: "header .next",
      prevEl: "header .prev"
    }
  });
}

function swiperContentCarousel() {
  new Swiper(".module--content-carousel .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: ".module--content-carousel .next",
      prevEl: ".module--content-carousel .prev"
    }
  });
}

export {
  swiperTestimonials,
  swiperNews,
  swiperTiles,
  swiperUsage,
  swiperBenefits,
  swiperBlog,
  swiperSocial,
  swiperTeam,
  swiperBrands,
  swiperSlides,
  swiperBrandsHome,
  swiperMediaCarousel,
  swiperContentCarousel
};
