import { gsap, Quad } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import $ from "jquery";
import { getRect } from "@util/functions";
import { Linear } from "gsap/all";
import { toFixed } from "@math/functions";
import lottie from "lottie-web";

window.ScrollTrigger = ScrollTrigger;

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);

gsap.config({
  nullTargetWarn: false
});

function tagsSlider() {
  Draggable.create(".categories", {
    bounds: ".categories-container",

    allowNativeTouchScrolling: true,
    type: "x"
  });
}

export { tagsSlider };
