import barba from "@barba/core";
import axios from "axios";
import ClipboardJS from "clipboard";
import customSelect from "custom-select";
import $ from "jquery";

function toggleNav() {
  const navToggler = document.querySelector(".navbar .toggler");
  const bodyDoc = document.querySelector("body");

  navToggler.addEventListener("click", function () {
    if (bodyDoc.classList.contains("is-menu-open")) {
      bodyDoc.classList.remove("is-menu-open");
    } else {
      bodyDoc.classList.add("is-menu-open");
    }
  });

  const menuItems = document.querySelectorAll(".menu-item");

  menuItems.forEach(function (menuItem) {
    menuItem.addEventListener("click", function (event) {
      if (!menuItem.classList.contains("menu-item-has-children")) {
        bodyDoc.classList.remove("is-menu-open");
      }
      this.classList.toggle("active");
      menuItems.forEach(function (otherMenuItem) {
        if (otherMenuItem !== menuItem) {
          otherMenuItem.classList.remove("active");
        }
      });
    });
  });
}

function accordionSuper() {
  $(".accordion").on("click", function () {
    const panel = $(this).next();
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(panel).css({ maxHeight: 0 });
    } else {
      $(this).addClass("active");
      $(".accordion").not(this).removeClass("active");
      const sHe = panel.prop("scrollHeight");
      $(panel).css({ maxHeight: sHe });
      $(".accordion").not(this).next().css({ maxHeight: 0 });
    }
  });
  $(".superpower > .accordion").eq(0).trigger("click");
  $(".revenue:not(.validate) .accordion").eq(0).trigger("click");
  // $(".superpowers .accordion").eq(0).trigger( "click" );
}

function accordion() {
  $(".accordion").on("click", function () {
    const panel = $(this).next();
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(panel).css({ maxHeight: 0 });
    } else {
      $(this).addClass("active");
      $(".accordion").not(this).removeClass("active");
      const sHe = panel.prop("scrollHeight");
      $(panel).css({ maxHeight: sHe });
      $(".accordion").not(this).next().css({ maxHeight: 0 });
    }
  });
}

function handleFaq() {
  $("section.faq li").on("click", function () {
    const panel = $(this.childNodes[1]).next();
    if ($(this.childNodes[1]).hasClass("active")) {
      $(this.childNodes[1]).removeClass("active");
      $(panel).css({ maxHeight: 0 });
    } else {
      $(this.childNodes[1]).addClass("active");
      $(".accordion").not(this.childNodes[1]).removeClass("active");
      const sHe = panel.prop("scrollHeight");
      $(panel).css({ maxHeight: sHe });
      $(".accordion").not(this.childNodes[1]).next().css({ maxHeight: 0 });
    }
  });

  $("section.faq .accordion__item").eq(0).trigger("click");
}

function textContentAccordion() {
  $("section.faq").each(function () {
    var parent = $(this);
    var accordionItem = $(this).find(".accordion__item");

    $(accordionItem).on("click", function () {
      const panel = $(this.childNodes[1]).next();
      if ($(this.childNodes[1]).hasClass("active")) {
        $(this.childNodes[1]).removeClass("active");
        $(panel).css({ height: 0 });
      } else {
        $(this.childNodes[1]).addClass("active");
        parent.find(".accordion").not(this.childNodes[1]).removeClass("active");
        $(panel).css({ height: "auto" });
        parent.find(".accordion").not(this.childNodes[1]).next().css({ height: 0 });
      }
    });
  });
}

function selectionCurrency() {
  $(".selection .btn").on("click", function () {
    $(".selection .btn").not(this).removeClass("active");
    $(this).addClass("active");
  });

  $(".selection .btn.eur").on("click", function () {
    $("body").addClass("show-eur");
  });

  $(".selection .btn.gbp").on("click", function () {
    $("body").removeClass("show-eur");
  });
}

function select() {
  customSelect("select");

  $("select.select-two").on("change", function () {
    const selectValue = $(this).find("option:selected").attr("data-class");

    $("section.form").find(`.btn[data-class="${selectValue}"]`).addClass("active");
    $("section.form .btn").not(`[data-class="${selectValue}"]`).removeClass("active");
  });
}

function thanks() {
  document.addEventListener(
    "wpcf7mailsent",
    function () {
      barba.go("/lab/weavr/talk-to-an-expert/calendar/");
    },
    false
  );
}

function components() {
  $(".component").on("click", function () {
    $(this).addClass("active");
    $(".component").not(this).removeClass("active");
    const currentItemIndex = Object.values(this.parentElement.getElementsByTagName("li")).indexOf(this);
    const matchingCard = Object.values($("section.components .end >div"))[currentItemIndex];
    $(matchingCard).addClass("active");
    $("section.components .end >div").not(matchingCard).removeClass("active");
  });
}

function pagination() {
  const pages = document.querySelectorAll(".benefits .numbers, .module--text-content-media-carousel .numbers");
  pages.forEach(page => {
    let slash = page.innerHTML;
    slash = slash.split(" / ");
    slash = slash.join('<span class="dash"></span>');
    const newPage = page;
    newPage.innerHTML = slash;
  });
}

function footerAccordion() {
  const footerText = document.querySelector("footer .end .text");
  const readMore = document.querySelector("footer .read-more");

  footerText.style.height = "5rem";

  readMore.addEventListener("click", () => {
    footerText.style.height = `${footerText.scrollHeight}px`;
    readMore.style.opacity = 0;
    readMore.style.visibility = "hidden";
  });
}

function copyToClipboard() {
  new ClipboardJS(".copy-link");
  $(".copy-link").on("click", function () {
    $(".copy-link span").text("Copied!");

    setTimeout(() => {
      $(".copy-link span").text("Copy link");
    }, 2000);
  });
}

function thanksCountry() {}

function addWidthAndHeight() {
  document.querySelectorAll("img").forEach(image => {
    if (image.getAttribute("width") == null || image.getAttribute("height") == null) image.setAttribute("width", "1");
    image.setAttribute("height", "1");
  });
}

function openVideo() {
  var isMobile = false; // initiate as false
  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  }

  const modal = document.querySelector("dialog");
  const videoSection = document.querySelector("section.video > video");
  const pausedVideo = document.querySelector("dialog video");
  const vids = document.querySelectorAll("video.autoplay");

  if (!isMobile) {
    vids.forEach(vid => {
      vid.play();
    });
  } else {
    vids.forEach(vid => {
      vid.pause();
    });
    modal.close();
    pausedVideo.pause();
  }

  document.addEventListener("click", e => {
    if (e.target === videoSection || e.target === pausedVideo) {
      modal.showModal();
      pausedVideo.play();
      document.body.classList.add("playing--video");
    } else if (document.body.classList.contains("playing--video")) {
      document.body.classList.remove("playing--video");
      modal.close();
      pausedVideo.pause();
    }
  });

  document.addEventListener("keydown", key => {
    if (key.key === "Escape") {
      pausedVideo.pause();
    }
  });
}

const videoModule = document.querySelectorAll(".module--video");

const videoModuleInit = function (videoModule) {
  if (videoModule.length <= 0) {
    return;
  }

  openVideo();
}; // videoModuleInit();
videoModuleInit(videoModule);

const ajaxLoadMore = () => {
  const button = document.querySelector(".load-more");

  if (typeof button !== "undefined" && button != null) {
    button.addEventListener("click", e => {
      e.preventDefault();

      const current_page = document.querySelector(".grid").dataset.page;
      const max_pages = document.querySelector(".grid").dataset.max;
      const params = new URLSearchParams();

      params.append("action", "load_more_posts");
      params.append("current_page", current_page);
      params.append("query", php_variables.posts);
      params.append("max_pages", max_pages);

      axios.post("/wp-admin/admin-ajax.php", params).then(res => {
        const posts_list = document.querySelector(".grid");
        posts_list.innerHTML += res.data.data;
        document.querySelector(".grid").dataset.page++;
        if (document.querySelector(".grid").dataset.page == document.querySelector(".grid").dataset.max) {
          button.parentNode.removeChild(button);
        }
      });
    });
  }
};

function imageCompare() {
  let compSlider = $(".comparison__slider");

  //let's loop through the sliders and initialise each of them
  compSlider.each(function () {
    let compSliderWidth = $(this).width() + "px";

    $(this).find(".resize img").css({
      width: compSliderWidth
    });
    drags($(this).find(".divider"), $(this).find(".resize"), $(this));
  });

  //if the user resizes the windows lets update our variables and resize our images
  $(window).on("resize", function () {
    let compSliderWidth = compSlider.width() + "px";
    compSlider.find(".resize img").css({
      width: compSliderWidth
    });
  });
}

function drags(dragElement, resizeElement, container) {
  // This creates a variable that detects if the user is using touch input insted of the mouse.
  let touched = false;
  window.addEventListener("touchstart", function () {
    touched = true;
  });
  window.addEventListener("touchend", function () {
    touched = false;
  });

  // clicp the image and move the slider on interaction with the mouse or the touch input
  dragElement
    .on("mousedown touchstart", function (e) {
      //add classes to the emelents - good for css animations if you need it to
      dragElement.addClass("draggable");
      resizeElement.addClass("resizable");
      //create vars

      let startX = e.pageX ? e.pageX : e.originalEvent.touches[0].pageX;
      let dragWidth = dragElement.outerWidth();
      let posX = dragElement.offset().left + dragWidth - startX;
      let containerOffset = container.offset().left;
      let containerWidth = container.outerWidth();
      let minLeft = containerOffset + 10;
      let maxLeft = containerOffset + containerWidth - dragWidth - 10;

      //add event listner on the divider emelent
      dragElement
        .parents()
        .on("mousemove touchmove", function (e) {
          // if the user is not using touch input let do preventDefault to prevent the user from slecting the images as he moves the silder arround.
          if (touched === false) {
            e.preventDefault();
          }

          let moveX = e.pageX ? e.pageX : e.originalEvent.touches[0].pageX;
          let leftValue = moveX + posX - dragWidth;

          // stop the divider from going over the limits of the container
          if (leftValue < minLeft) {
            leftValue = minLeft;
          } else if (leftValue > maxLeft) {
            leftValue = maxLeft;
          }

          let widthValue = ((leftValue + dragWidth / 2 - containerOffset) * 100) / containerWidth + "%";

          $(".draggable")
            .css("left", widthValue)
            .on("mouseup touchend touchcancel", function () {
              $(this).removeClass("draggable");
              resizeElement.removeClass("resizable");
            });

          $(".resizable").css("width", widthValue);
        })
        .on("mouseup touchend touchcancel", function () {
          dragElement.removeClass("draggable");
          resizeElement.removeClass("resizable");
        });
    })
    .on("mouseup touchend touchcancel", function (e) {
      // stop clicping the image and move the slider
      dragElement.removeClass("draggable");
      resizeElement.removeClass("resizable");
    });
}

function hubspotBooking() {
  const meeting_iframe = document.querySelector(".meetings-iframe-container");
  if (meeting_iframe) {
    return new Promise(resolve => {
      setTimeout(() => {
        const box = document.querySelector(".meetings-iframe-container");
        const script = document.createElement("script");
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        box.appendChild(script);
        script.async = true;

        script.onload = () => {
          console.log("Hubspot loaded successfuly");
        };
        resolve();
      }, 1000);
    });
  }
}

function imageCaptions() {
  const image_captions = document.querySelector(".image__captions");
  if (image_captions) {
    $(window).on("resize scroll", function () {
      $(".image__caption").each(function () {
        if (isInViewport($(this))) {
          $(this).addClass("image__caption--ready");
        }
      });
    });
  }
}

function isInViewport(element) {
  if (typeof element.offset() !== "undefined") {
    var elementTop = element.offset().top;
    var elementBottom = elementTop + element.outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
}

export {
  select,
  toggleNav,
  accordion,
  accordionSuper,
  handleFaq,
  textContentAccordion,
  thanks,
  selectionCurrency,
  components,
  pagination,
  footerAccordion,
  copyToClipboard,
  thanksCountry,
  ajaxLoadMore,
  addWidthAndHeight,
  openVideo,
  imageCompare,
  drags,
  hubspotBooking,
  imageCaptions
};
