import {
  swiperBenefits,
  swiperBlog,
  swiperBrands,
  swiperNews,
  swiperTiles,
  swiperSlides,
  swiperSocial,
  swiperTeam,
  swiperTestimonials,
  swiperUsage,
  swiperBrandsHome,
  swiperMediaCarousel,
  swiperContentCarousel
} from "@modules/slide";
import {
  accordion,
  accordionSuper,
  ajaxLoadMore,
  components,
  copyToClipboard,
  handleFaq,
  textContentAccordion,
  pagination,
  select,
  selectionCurrency,
  thanksCountry,
  openVideo,
  imageCompare,
  hubspotBooking,
  imageCaptions,
  drags
} from "@modules/tricks";
import { tagsSlider } from "@ui/animations";

export default [
  {
    namespace: "home",
    beforeEnter() {
      select();
    },
    afterEnter() {
      document.querySelector(".anim_content .anim video").play();
      swiperTestimonials();
      swiperNews();
      swiperTiles();
      swiperBrandsHome();
      accordionSuper();
    }
  },
  {
    namespace: "archive-blog",
    afterEnter() {
      swiperBlog();
      ajaxLoadMore();
      tagsSlider();
      return new Promise(resolve => {
        setTimeout(() => {
          const box = document.querySelector(".newsletter");
          const script = document.createElement("script");
          script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
          box.appendChild(script);
          script.async = true;

          script.onload = () => {
            const formhub = document.querySelector("#hbspt-form-751a14c4-2b78-47a3-bcb3-3383ad8d46ea");
            hbspt.forms.create({
              region: "eu1",
              portalId: "25069173",
              formId: "1edd586c-8988-4907-b444-86ca8a6a4784"
            });
            // eslint-disable-next-line object-shorthand
            console.log("Hubspot loaded successfuly");
          };
          resolve();
        }, 1000);
      });
    }
  },
  {
    namespace: "single-blog",
    afterEnter() {
      // swiperNews();
      copyToClipboard();
      return new Promise(resolve => {
        setTimeout(() => {
          const hs_cta_embeds = document.querySelectorAll(".hs-cta-embed");
          hs_cta_embeds.forEach(function (hs_cta_embed) {
            window.hubspot_web_interactives_running = false;
            const script = document.createElement("script");
            script.src = "https://js.hubspot.com/web-interactives-embed.js";
            document.body.appendChild(script);
          });

          const box = document.querySelector(".newsletter");
          const script = document.createElement("script");
          script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
          box.appendChild(script);
          script.async = true;

          script.onload = () => {
            const formhub = document.querySelector("#hbspt-form-751a14c4-2b78-47a3-bcb3-3383ad8d46ea");
            hbspt.forms.create({
              region: "eu1",
              portalId: "25069173",
              formId: "751a14c4-2b78-47a3-bcb3-3383ad8d46ea"
            });
            // eslint-disable-next-line object-shorthand
            console.log("Hubspot loaded successfuly");
          };
          resolve();
        }, 1000);
      });
    }
  },
  {
    namespace: "single-lead",
    afterEnter() {
      swiperBrandsHome();
      swiperSlides();
      accordionSuper();

      return new Promise(resolve => {
        setTimeout(() => {
          const scripts = document.querySelectorAll(".form script");
          scripts.innerHTML;
          resolve();
        }, 100);
      });
    }
  },
  {
    namespace: "page-templates/product",
    beforeEnter() {
      handleFaq();
      openVideo();
    }
  },
  {
    namespace: "page-templates/embedded",
    afterEnter() {
      accordionSuper();
    }
  },
  {
    namespace: "page-templates/why",
    afterEnter() {
      swiperSlides();
      accordion();
      swiperTestimonials();
      select();
    }
  },
  {
    namespace: "page-templates/about",
    beforeEnter() {
      openVideo();
    },
    afterEnter() {
      swiperBrands();
      swiperTeam();
      swiperSocial();
      swiperTestimonials();
      select();
      return new Promise(resolve => {
        setTimeout(() => {
          const box = document.querySelector(".newsletter");
          const script = document.createElement("script");
          script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
          box.appendChild(script);
          script.async = true;

          script.onload = () => {
            const formhub = document.querySelector("#hbspt-form-751a14c4-2b78-47a3-bcb3-3383ad8d46ea");
            hbspt.forms.create({
              region: "eu1",
              portalId: "25069173",
              formId: "1edd586c-8988-4907-b444-86ca8a6a4784"
            });
            // eslint-disable-next-line object-shorthand
            console.log("Hubspot loaded successfuly");
          };
          resolve();
        }, 1000);
      });
    }
  },
  {
    namespace: "page-templates/pricing",
    beforeEnter() {
      accordion();
    }
  },
  {
    namespace: "page-templates/pricing-details",
    beforeEnter() {
      selectionCurrency();
      accordion();
    }
  },
  {
    namespace: "page-templates/talk",
    beforeEnter() {
      // thanks();
      thanksCountry();
    }
  },
  {
    namespace: "page-templates/plugin",
    afterEnter() {
      swiperUsage();
      swiperTestimonials();
      swiperBenefits();
      handleFaq();
      components();
      pagination();
    }
  },
  {
    namespace: "page-templates/hubpost",
    beforeEnter() {
      return new Promise(resolve => {
        setTimeout(() => {
          const box = document.querySelector(".hubspot-form");
          const script = document.createElement("script");
          script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
          box.appendChild(script);
          script.async = true;

          script.onload = () => {
            const formhub = document.querySelector("#hsForm_f2526eb4-a06d-4d59-9681-430c039c4386");
            hbspt.forms.create({
              region: "eu1",
              portalId: "25069173",
              formId: "f2526eb4-a06d-4d59-9681-430c039c4386",
              // eslint-disable-next-line object-shorthand
              onFormSubmit: function (form) {
                setTimeout(function () {
                  const country__drop_down_ = form.querySelector('select[name="country__drop_down_"]').value;
                  if (country__drop_down_ === "United Kingdom" || country__drop_down_ === "Ireland") {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-uk-ireland/";
                  } else if (country__drop_down_ === "Spain") {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-spain/";
                  } else if (country__drop_down_ === "Italy") {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-italy/";
                  } else if (country__drop_down_ === "Belgium") {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-belgium/";
                  } else if (
                    country__drop_down_ === "Netherlands" ||
                    country__drop_down_ === "Holland" ||
                    country__drop_down_ === "Luxembourg"
                  ) {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-netherlands/";
                  } else if (country__drop_down_ === "Portugal") {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-portugal/";
                  } else if (
                    country__drop_down_ === "Germany" ||
                    country__drop_down_ === "Austria" ||
                    country__drop_down_ === "Switzerland"
                  ) {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-dach/";
                  } else {
                    window.location = "https://www.weavr.io/talk-to-an-expert/calendar-other/";
                  }
                }, 250);
              }
            });
            console.log("Hubspot loaded successfuly");
          };
          resolve();
        }, 1000);
      });
    }
  },
  {
    namespace: "page-templates/epr",
    afterEnter() {
      textContentAccordion();
      openVideo();
    }
  },
  {
    namespace: "page-templates/use-case",
    afterEnter() {
      textContentAccordion();
      swiperMediaCarousel();
      pagination();
      swiperContentCarousel();
      imageCompare();
      drags();
      openVideo();
    }
  },
  {
    namespace: "page-templates/content-modules-pages",
    beforeEnter() {},
    afterEnter() {
      imageCaptions();
      openVideo();
      hubspotBooking();
      textContentAccordion();
      swiperMediaCarousel();
      pagination();
      swiperContentCarousel();
      imageCompare();
      drags();
    }
  }
];
