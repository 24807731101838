/*!
 * MotherLoader: 0.1.2
 * http://andreponce.com
 *
 */

import EventDispatcher from "@event/EventDispatcher";
import {
  canUseWebP,
  canUseAvif,
  createElement,
  getExtension,
  getScreenSize,
  query,
  queryAll,
  searchInManifest
} from "@util/functions";
import { toFixed } from "@math/functions";

const PNGPlaceHolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
const SVGPlaceHolder =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMSIgaGVpZ2h0PSIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InRyYW5zcGFyZW50Ii8+PC9zdmc+";
const LOOK_FOR_ELEMENTS = ["img", "video", "object", "use", "image", "audio"];
const CURRENT_SRC_ELEMENTS = ["img", "video", "audio"];

const SCREEN_SIZE = getScreenSize();
let webPSupport;
let avifSupport;
canUseWebP().then(res => (webPSupport = res));
canUseAvif().then(res => (avifSupport = res));

class MotherLoaderEvent extends Event {
  static START = "start";
  static READY = "ready";
  static PROGRESS = "progress";
  static COMPLETE = "complete";
  static COMPLETE_ALL = "complete_all";
  static ERROR = "error";

  constructor(type, data, progress, eventInitDict) {
    super(type, eventInitDict);
    this.data = data;
    this.progress = progress;
  }
}

class MotherLoader extends EventDispatcher {
  verbose;
  queue;

  #baseURL;
  #assets = [];
  #assetsByPath = {};
  #assetsById = {};
  #loading = false;

  waitingSetup = false;
  started = false;
  complete = false;
  progress = 0;

  constructor(params) {
    super();
    const { verbose, queue, baseURL } = params || {};
    const base = query("base");
    this.#baseURL = new URL(baseURL ? location.protocol + baseURL : (base ? base.href : null) || location.origin);
    this.verbose = verbose;
    this.queue = queue;

    this.#log("MotherLoader::constructor(👩) ", this);
  }

  /**
   *
   * @param {HTMlElement} el
   */
  scan = (el, params = {}) => {
    //setTimeout -> fix safari delay to read currentSrc
    this.#log("MotherLoader::scan() ", el, params);
    this.#log("-------------- scanning");
    let { lookFor } = params;
    lookFor = lookFor || LOOK_FOR_ELEMENTS;
    let ignoredElements = [...queryAll(`[ignoreLoader] ${lookFor.join(", [ignoreLoader] ")}`, el)];
    let elements = [...queryAll(lookFor.join(","), el)];
    this.waitingSetup = true;
    let timer = setInterval(() => {
      const notOkElements = [];
      elements.forEach((el, i) => {
        const { ignoreLoader, loading, responsive, bestformat, weight } = el.attributes;
        if (
          ignoreLoader ||
          (loading && loading.value == "lazy") ||
          ignoredElements.includes(el) ||
          elements.includes(el.parentNode)
        )
          return;

        const tagName = el.tagName.toLowerCase();
        if (
          CURRENT_SRC_ELEMENTS.includes(tagName) &&
          !el.currentSrc &&
          ((tagName == "img" && (el.srcset || el.parentNode.tagName.toLowerCase() == "picture")) || tagName != "img")
        ) {
          notOkElements.push(el);
          return;
        }
        let src =
          el.currentSrc ||
          el.src ||
          (el.href ? el.href.baseVal || (typeof el.href == "string" ? el.href : null) : null) ||
          el.data;

        const hasSrc = Boolean(src);
        src = src || el.getAttribute("from");
        if (!src) {
          console.warn("This element does not have a source url", el);
          return;
        }

        const myParams = { ...params };
        myParams.resposive = responsive ? responsive.value === "true" : myParams.resposive;
        myParams.bestFormat = bestformat ? bestformat.value === "true" : myParams.bestFormat;
        myParams.weight = weight ? parseFloat(weight.value) : 1;

        el.onload = null;
        el.onerror = null;
        el.removeAttribute("from");

        if (hasSrc) {
          switch (tagName) {
            case "object":
              el.data = PNGPlaceHolder;
              break;
            case "use":
            case "image":
              el.setAttribute("href", SVGPlaceHolder);
              break;
            case "audio":
            case "video":
              el.src = "";
              break;
            default:
              el.src = PNGPlaceHolder;
          }
        }
        if (["audio"].includes(tagName)) myParams.resposive = false;

        this.add(src, { id: el.id || src, el: el, ...myParams });
      });
      if (!notOkElements.length) {
        this.#log("-------------- end scan");
        // this.#_dispatchEvent(MotherLoaderEvent.READY);
        clearInterval(timer);
        this.waitingSetup = false;
        if (this.started) this.start();
      } else elements = notOkElements;
    }, 1);

    return this;
  };

  /**
   *
   * @param {*} url
   * @param {*} params
   */
  add = (filename, params = {}) => {
    if (!filename || this.#loading) {
      if (this.#loading) console.warn("You cannot add new assets to the queue while loading is in progress.");
      else console.warn("Filename is empty");
      return this;
    }
    const { requestInit, bestFormat, resposive, formatType, path, weight } = params;
    params.id = params.id || filename;
    const base = filename.includes("http") ? undefined : this.#baseURL.href;
    const normalizedOriginalPath = `${path ? `${path}/` : ""}${filename}`;
    const tempUrl = new URL(normalizedOriginalPath, base);
    let pathname = tempUrl.pathname;
    let extension = getExtension(pathname);
    const fileIndex = extension ? pathname.lastIndexOf("/") : pathname.length;
    const originalFileName = extension ? pathname.slice(fileIndex + 1) : "";

    pathname = pathname.slice(0, fileIndex);
    const pathList = pathname.replace(this.#baseURL.pathname, "").replace(/^\/+/, "").split("/");

    let finalFileName = originalFileName;

    if (extension) {
      const isStaticImage = ["png", "jpg", "jpeg"].includes(extension);
      if (isStaticImage || formatType == "video") {
        const newExtension =
          bestFormat && isStaticImage ? (avifSupport ? "avif" : webPSupport ? "webp" : extension) : extension;
        const regexp = /@.x/gm;
        const fileSize = `@${SCREEN_SIZE}x`;
        const match = finalFileName.match(regexp);
        if (resposive) {
          finalFileName = match
            ? finalFileName.replace(regexp, fileSize)
            : finalFileName.replace(`.${extension}`, `${fileSize}.${extension}`);
          // finalFileName = match ? finalFileName.replace(new RegExp(`(?<=${fileSize})(.*)(?=.${extension})`, 'gm'), '') : finalFileName;
          finalFileName = match
            ? finalFileName.replace(finalFileName.match(`${fileSize}(.*).${extension}`)[1], "")
            : finalFileName;
        }
        finalFileName = finalFileName.replace(`.${extension}`, `.${newExtension}`);
        extension = newExtension;
      }
    }
    finalFileName = searchInManifest(finalFileName, pathList) || originalFileName;

    // this.verbose = true;
    // this.#log(filename, pathArr, url);
    // this.verbose = false;

    const uniquePath = tempUrl.origin + pathname + "/" + finalFileName + tempUrl.search;
    const finalURL = new URL(normalizedOriginalPath.replace(originalFileName, finalFileName), base);
    let assetsArr = this.#assetsByPath[uniquePath];
    params.path = params.path || pathList.join("/");
    params.weight = weight || 1;

    const data = {
      originalPath: normalizedOriginalPath,
      filename: finalFileName,
      extension: extension,
      uniquePath: uniquePath,
      pathList: pathList,
      url: finalURL,
      ...params
    };

    if (!data.el && (!requestInit || (requestInit && formatType))) {
      let tagName;
      let type = formatType || extension;
      switch (type) {
        case "image":
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
        case "webp":
        case "avif":
          tagName = "img";
          break;
        case "svg":
          tagName = "object";
          break;
        case "video":
          tagName = "video";
          break;
        case "audio":
        case "mp3":
        case "wav":
        case "aac":
        case "ogg":
        case "ogv":
        case "webm":
        case "mp4":
          tagName = "audio";
          break;
      }
      data.el = tagName ? createElement(tagName) : null;
    }

    if (!assetsArr) {
      this.#log("Added to Loader: ", data);
      this.#assetsByPath[uniquePath] = [data];
      this.#assets.push(data);
    } else {
      this.#log("---> Repeated Asset: ", data);
      assetsArr.push(data);
    }

    this.#assetsById[params.id] = data;

    return this;
  };

  // #_dispatchEvent(type, data, progress) {
  //   const evt = new MotherLoaderEvent(type, data, progress);
  //   this.dispatchEvent(evt);
  // }

  #log = (...args) => {
    if (this.verbose) {
      console.log(...args);
    }
  };

  start = () => {
    if (this.complete) {
      console.warn("The loader has already been completed.");
      return;
    }
    this.started = true;
    if (this.waitingSetup || this.#loading) return this;
    this.#log("MotherLoader::start(🚀) ");
    this.#log("-------------- start loading");
    this.#loading = true;

    const totalWeight = this.#assets.reduce((accumulator, data) => accumulator + data.weight, 0);
    let weightLoaded = 0;
    let assetsLoaded = 0;
    const getPct = () => toFixed(weightLoaded, 3) / toFixed(totalWeight, 3);
    let pct = 0;
    let errors = 0;
    const checkProgress = () => {
      this.progress = pct;
      if (pct == 1) {
        this.complete = true;
        this.started = false;
        this.#loading = false;
        this.#log(
          `-------------- ${errors ? "💩💩💩 Load complete, but there were some errors!" : "✅ ✅ ✅ 🙌 Complete ALL!"}`
        );
        // this.#_dispatchEvent(MotherLoaderEvent.COMPLETE_ALL, null, pct);
      } else if (this.queue) load(this.#assets[assetsLoaded]);
    };
    const load = data => {
      if (!data) return;
      const {
        el,
        uniquePath,
        fullLoad,
        crossorigin,
        mimeType,
        responseType,
        requestInit,
        onStart,
        onComplete,
        onError
      } = data;

      const onLoad = () => {
        weightLoaded += data.weight;
        assetsLoaded++;
        pct = getPct();
        this.#log("✅ Load complete", data, ` Progress: ${pct * 100}%`);
        // this.#_dispatchEvent(MotherLoaderEvent.PROGRESS, data, pct);
        if (onComplete) onComplete(data);
        // this.#_dispatchEvent(MotherLoaderEvent.COMPLETE, data, pct);
        checkProgress();
      };
      const _onError = () => {
        weightLoaded += data.weight;
        assetsLoaded++;
        errors++;
        pct = getPct();
        // console.log('erro', data);
        this.#log("❌ Error", data, ` Progress: ${pct * 100}%`);
        if (onError) onError(data);
        // this.#_dispatchEvent(MotherLoaderEvent.ERROR, data, pct);
        // this.#_dispatchEvent(MotherLoaderEvent.PROGRESS, data, pct);
        checkProgress();
      };

      if (el) {
        if (crossorigin) el.setAttribute("crossorigin", crossorigin);
        el.onerror = _onError;
        const tagName = el.tagName.toLowerCase();
        switch (tagName) {
          case "video":
          case "audio":
            el.addEventListener(fullLoad ? "canplaythrough" : "loadedmetadata", onLoad, { once: true });
            break;
          case "use":
          case "object":
          case "image":
            const fakeImg = new Image();
            fakeImg.addEventListener("load", onLoad, { once: true });
            fakeImg.addEventListener("error", _onError, { once: true });
            fakeImg.src = uniquePath;
            break;
          default:
            el.addEventListener("load", onLoad, { once: true });
        }
      } else {
        fetch(new Request(uniquePath, requestInit || {}))
          .then(response => {
            if (response.ok) {
              switch (responseType) {
                case "arraybuffer":
                  return response.arrayBuffer();

                case "blob":
                  return response.blob();

                case "document":
                  return response.text().then(text => {
                    const parser = new DOMParser();
                    return parser.parseFromString(text, mimeType || "application/xhtml+xml");
                  });

                case "json":
                  return response.json();

                default:
                  if (mimeType === undefined) {
                    return response.text();
                  } else {
                    const re = /charset="?([^;"\s]*)"?/i;
                    const exec = re.exec(mimeType);
                    const label = exec && exec[1] ? exec[1].toLowerCase() : undefined;
                    const decoder = new TextDecoder(label);
                    return response.arrayBuffer().then(ab => decoder.decode(ab));
                  }
              }
            } else {
              console.error("Network response was not ok.");
              return Promise.reject(response);
            }
          })
          .then(result => {
            data.result = result;
            onLoad();
          })
          .catch(e => {
            data.result = e;
            console.error("There has been a problem with your fetch operation: " + e.message);
            _onError();
          });
      }

      /*fetch(uniquePath)
				.then(response => response.blob())
				.then(blob => {
					var url = URL.createObjectURL(blob);
					console.log(url, blob, obj);
					this.#assetsByPath[uniquePath].forEach(obj => {
						const el = obj.el;
						if (el) {
							const tagName = el.tagName.toLowerCase();

							switch (tagName) {
								case 'object':
									el.data = url;
									break;
								case 'use':
								case 'image':
								case 'link':
									el.setAttribute('href', url);
									break;
								default:
									el.src = url;
							}
						}
					});
				})*/

      if (onStart) onStart(data);
      // this.#_dispatchEvent(MotherLoaderEvent.START, data);

      this.#assetsByPath[data.uniquePath].forEach(data => {
        const el = data.el;
        if (el) {
          const tagName = el.tagName.toLowerCase();
          const fullPath = data.url.href;

          switch (tagName) {
            case "script":
            case "link":
              query("head").appendChild(el);
              break;
          }

          switch (tagName) {
            case "object":
              el.data = fullPath;
              break;
            case "use":
            case "image":
            case "link":
              el.setAttribute("href", fullPath);
              break;
            default:
              el.src = fullPath;
          }
        }
      });
    };

    if (this.queue) load(this.#assets[assetsLoaded]);
    else this.#assets.forEach(data => load(data));

    return this;
  };

  get = id => {
    return this.#assetsById[id];
  };

  pause = () => {};

  resume = () => {};

  abort = () => {};

  destroy = () => {};
}

export { MotherLoader, MotherLoaderEvent };
